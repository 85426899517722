
import { H1 } from "@blueprintjs/core";
import * as React from "react";
import { urls } from "../../global/firebase";
import '../../assets/css/style.css'

export  class LocationCouponData extends React.Component{

public state:any={
    coupons:[],
    yardapikey:'',
}

public  componentDidMount(): void {
    const params = new URL(window.location.href).searchParams;  
    const yardapikey = params.get("yard_api_key")!; // is the string "Jonathan Smith".
    this.setState({yardapikey})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(urls.pointsystem+"p1s5e1_get_location_coupon_data", requestOptions)
      .then(response => response.json())
      .then(result => {
         this.setState({coupons:result})   
         console.log(result);
          
        })
      .catch(error => console.log('error', error));
}


public render(){
    const styleTh=  {
        padding: '4px',
        border: '1px solid #000'
    }
    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'15px',
        cursor:'pointer'
    }
    return(
        <div style={{padding:'13px',fontSize:'17px',textAlign:'left',background:'#fff'}}>
            <H1>Yard Api Key: {this.state.yardapikey}</H1>
            {
           Object.keys(this.state.coupons).sort().map((x:any)=>(
                  x!=='errors'?  <details open={true} style={{margin:'10px',background:'slategray',padding:'5px',width:'90vw'}}>
                    <summary><span style={stylespan}>{x}</span></summary>
                    <div style={{marginLeft:'20px'}}>
                    <details open={true} style={{margin:'10px',background: 'antiquewhite',padding:'4px'}}>
                        <summary><span style={stylespan2}>{'Coupons'}</span></summary>
                        <p  style={{marginLeft:'20px',marginTop:'8px',overflow:'auto'}}>                       
                                <tr>
                                    <th style={styleTh}>coupon_cap_amount</th>
                                    <th style={styleTh}>coupon_fixed_amount</th>
                                    <th style={styleTh}>coupon_percentage</th>
                                    <th style={styleTh}>coupon_type</th>
                                    <th style={styleTh}>coupon_uid</th>
                                    <th style={styleTh}>expiry_date</th>
                                    <th style={styleTh}>issue_date</th>
                                </tr>
                            {this.state.coupons[x].coupons!==undefined && this.state.coupons[x].coupons.length>0 ? this.state.coupons[x].coupons.map((r:any)=>(
                                <tr>
                                    <td style={styleTh}>{r.coupon_cap_amount}</td>
                                    <td style={styleTh}>{r.coupon_fixed_amount}</td>
                                    <td style={styleTh}>{r.coupon_percentage}</td>
                                    <td style={styleTh}>{r.coupon_type}</td>
                                    <td style={styleTh}>{r.coupon_uid}</td>
                                    <td style={styleTh}>{r.expiry_date}</td>
                                    <td style={styleTh}>{r.issue_date}</td>
                                </tr>
                            )):'Coupon Not Found'}
                       
                        </p>
                        </details>
                        <details style={{margin:'10px',background:'ghostwhite',padding:'4px'}}>
                        <summary><span style={stylespan2}>{'Next Coupons'}</span></summary>
                        {this.state.coupons[x]!==undefined  && this.state.coupons[x].next_coupon!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                <tr>
                                <th style={styleTh}>coupon_cap_amount</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.coupon_cap_amount}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>coupon_fixed_amount</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.coupon_fixed_amount}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>coupon_percentage</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.coupon_percentage}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>coupon_type</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.coupon_type}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>coupon_uid</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.coupon_uid}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>progress_left</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.progress_left}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>progress_towards</th>
                                <td style={styleTh}>{this.state.coupons[x].next_coupon.progress_towards}</td>
                                </tr>
                        </p>
                        :'Coupon Not Found'}
                        </details>
                        <details style={{margin:'10px',background:'skyblue',padding:'4px'}}>
                        <summary><span style={stylespan2}>{'Spent Amounts'}</span></summary>
                        {this.state.coupons[x]!==undefined  && this.state.coupons[x].spent_amounts!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                <tr>
                                <th style={styleTh}>last_3_months</th>
                                <td style={styleTh}>{this.state.coupons[x].spent_amounts.last_3_months.toFixed(2)}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>last_6_months</th>
                                <td style={styleTh}>{this.state.coupons[x].spent_amounts.last_6_months.toFixed(2)}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>last_month</th>
                                <td style={styleTh}>{this.state.coupons[x].spent_amounts.last_month.toFixed(2)}</td>
                                </tr>
                                <tr>
                                <th style={styleTh}>last_year</th>
                                <td style={styleTh}>{this.state.coupons[x].spent_amounts.last_year.toFixed(2)}</td>
                                </tr>
                        </p>
                        :''}
                    </details>
                    </div>
                    </details>
           :'' ))
          }
        </div>
    )
}


}


