const BUSINESSES = 'BUSINESSES';
const FETCHING = 'FETCHING';
const ARCHIVEBUSINESSES = 'ARCHIVEBUSINESSES';
const LEN = 'LEN';


export function addbusiness(allbusinesses:any) {
  return {
    type: BUSINESSES,
    allbusinesses,
  }
}

export function addArchivebusiness(allarbusinesses:any) {
  return {
    type: ARCHIVEBUSINESSES,
    allarbusinesses,
  }
}

export function fetchingBool(fetch:any) {
  return {
    type: FETCHING,
    fetch,
  }
}

export function fetchinglen(len:any) {
  return {
    type: LEN,
    len,
  }
}

const defaultbusiness ={ 
     Businesses: [],
     Archived:[],
     fetching:true,
     len:0,
    }

function BusinessesReducer(state=defaultbusiness, action:any) {    
  switch (action.type) {
    case BUSINESSES:
      return {
          Businesses: action.allbusinesses,  
          Archived: state.Archived,      
          fetching:state.fetching,
          len:state.len   
      };
    case ARCHIVEBUSINESSES:
        return {
          Businesses: state.Businesses, 
          Archived: action.allarbusinesses,     
          fetching:state.fetching,
          len:state.len      
        };
    case FETCHING:
          return {
              Businesses: state.Businesses, 
              Archived: state.Archived,  
              fetching:action.fetch,
              len:state.len            
          };
      case LEN:
          return {
              Businesses: state.Businesses, 
              Archived: state.Archived,  
              fetching:state.fetching,
              len:action.len            
          };
    default:
      return state;
  }
}


  export default BusinessesReducer