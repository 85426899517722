import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

import { AuthProvider } from 'react-auth-kit';
import { Provider } from 'react-redux';
import store from './actions/store/store'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

     <Provider store={store}>
        <AuthProvider authType = {'localstorage'}
                  authName={'_auth'}                
                  cookieDomain={window.location.hostname}
                  cookieSecure={window.location.protocol === "https:"}>
    <App />
    </AuthProvider>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
