
import * as React from "react";

const BusinessAndSiteSelect =(dat:any)=>{
const businessSelected = dat.businessSelected;
const SiteSelected=dat.SiteSelected;
const busData = dat.busData;
const siData=dat.siData;

    return (  
        <div className="float-right" style={{display:'grid'}}>    
        <select onChange={(e:any)=>businessSelected(e)}>         
         {
         busData.map((u:any,i:any)=> <option key={i} value={u.businessId} >{u.path}</option>)
         }
        </select>
        <select  onChange={(e)=>SiteSelected(e)}>
        {
         siData.map((up:any,ip:any)=> <option key={ip} value={up.siteId} >{up.path}</option>)
         }
        </select>
        </div>
    )
}

export default BusinessAndSiteSelect;