

import {addArchivebusiness, addbusiness, fetchingBool, fetchinglen}  from '../actions/businesses/PushBusinessesReducer';
import { BusinessesCollection, firebase } from '../global/firebase';
import { collection, getDocs, query ,orderBy} from 'firebase/firestore';
import store from './store/store';
// import { orderBy } from 'lodash';

 const initApp=async()=>{ 


console.log('init hit');

try {
const userState = localStorage.getItem('_auth_state');
if(userState!==undefined && userState!==null){
  const user =   JSON.parse(userState).accountData;
    const sitesx =user.permissions.sites!==undefined && user.permissions.sites.length>0? filtersites(user.permissions.sites) : []
    const q = query(BusinessesCollection,orderBy("path"));
    const doc =await getDocs(q)
    const b:any =[]
    const archivebusienss:any =[]
    if(!doc.empty){
    doc.forEach(async(r)=>{
      if(user.permissions.businesses.includes(r.id) || user.permissions.businesses.length<=0 && r.data().isArchive!==1 && r.data().isArchive!=='1'){
     const data:any= r.data()   
     const qs = query(collection(firebase, 'businesses/'+r.id+'/sites'));
     const docs =await getDocs(qs)
     if(!docs.empty){
      const arrSite:any =[]  
      docs.forEach((site)=>{

        if(sitesx.includes(site.id) || sitesx.length<=0){
          const sitedata= site.data()
          sitedata.siteId =site.id
          arrSite.push(sitedata)
        }

      })
      data.sites=arrSite
    }
     b.push(data);
    }else if(r.data().isArchive!==undefined && (r.data().isArchive===1 || r.data().isArchive==='1')){
      const data:any= r.data()   
      const qs = query(collection(firebase, 'businesses/'+r.id+'/sites'));
      const docs =await getDocs(qs)
      if(!docs.empty){
       const arrSite:any =[]  
       docs.forEach((site)=>{
         if(sitesx.includes(site.id) || sitesx.length<=0){
          const sitedata= site.data()
          sitedata.siteId =site.id
          arrSite.push(sitedata)
         }
       })
       data.sites=arrSite
     }
     archivebusienss.push(data);
    }
    });

   store.dispatch(addbusiness(b))
   store.dispatch(addArchivebusiness(archivebusienss))

   store.dispatch(fetchinglen(doc.size))  
      }
      

    }

  
    store.dispatch(fetchingBool(false))
 
    
} catch (error) {
    window.location.href=''
}




  }


export default initApp    


  const filtersites=(sites:any)=>{
   return sites.filter((r:any)=>{
if(r!==undefined && r!==null && r!=='' && r!==' '){
return true
}else{
return false
}
   })
  }
