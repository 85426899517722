
import { H1 } from "@blueprintjs/core";
import * as React from "react";
import { urls } from "../../global/firebase";
import '../../assets/css/style.css'

export  class CustomerInfo extends React.Component{

public state:any={
    coupons:[],
    yardapikey:'',
    consumer_phone_number:'',
}

public  componentDidMount(): void {
    const params = new URL(window.location.href).searchParams;  
    const yardapikey = params.get("yard_api_key")!; // is the string "Jonathan Smith".
    const Consumerphonenumber = params.get("consumer_phone_number")!; // is the string "Jonathan Smith".
    this.setState({yardapikey,consumer_phone_number:Consumerphonenumber})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    urlencoded.append("consumer_phone_number", Consumerphonenumber);
    try {
 
   const el:any= document.querySelector('.bp5-navbar')!
     el.style.display = 'none'
            
    } catch (error) {
        console.log(error);
        
    }
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(urls.pointsystem+"p1s5e4_get_consumer_information", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        result.coupons.map((x:any)=>{
console.log(x,'jiooji');
Object.keys(x).map((l:any)=>{
console.log(x[l],'oooooo');

})
        })
         this.setState({coupons:result.coupons})   
         
          
        })
      .catch(error => console.log('error', error));
}


public render(){
    const styleTh=  {
        padding: '4px',
        border: '1px solid #000'
    }
    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'15px',
        cursor:'pointer'
    }
    return(
        <div style={{padding:'13px',fontSize:'17px',textAlign:'left',background:'#fff'}}>
            <H1>Yard Api Key: {this.state.yardapikey}</H1>
            <H1>consumer phone number: {this.state.consumer_phone_number}</H1>
            {
           this.state.coupons.map((x:any)=>(
                  <details open={true} style={{margin:'10px',background:'slategray',padding:'5px',width:'90vw'}}>
                    <summary><span style={stylespan}>{this.state.consumer_phone_number}</span></summary>
                    <div style={{marginLeft:'20px'}}>
                    <details open={true} style={{margin:'10px',background: 'antiquewhite',padding:'4px'}}>
                        <summary><span style={stylespan2}>{'Coupons'}</span></summary>
                        <p  style={{marginLeft:'20px',marginTop:'8px',overflow:'auto'}}>                       
                                <tr>
                                
                                   <th style={styleTh}>amount_spent</th>
                                    <th style={styleTh}>coupon_cap_amount</th>
                                    <th style={styleTh}>coupon_fixed_amount</th>
                                    <th style={styleTh}>coupon_percentage</th>
                                    <th style={styleTh}>coupon_type</th>
                                    <th style={styleTh}>coupon_uid</th>
                                    <th style={styleTh}>expiry_date</th>
                                    <th style={styleTh}>issue_date</th>
                                    <th style={styleTh}>date time used</th>
                                    <th style={styleTh}>transaction_amount</th>
                                    <th style={styleTh}>transaction_id</th>
                                    <th style={styleTh}>coupon_used</th>
                                </tr>
                            {Object.keys(x).map((r:any)=>(
                                <tr>
                                    <td style={styleTh}>{x[r].amount_spent}</td>
                                    <td style={styleTh}>{x[r].coupon_cap_amount}</td>
                                    <td style={styleTh}>{x[r].coupon_fixed_amount}</td>
                                    <td style={styleTh}>{x[r].coupon_percentage}</td>
                                    <td style={styleTh}>{x[r].coupon_type}</td>
                                    <td style={styleTh}>{x[r].coupon_uid}</td>
                                    <td style={styleTh}>{x[r].expiry_date}</td>
                                    <td style={styleTh}>{x[r].issue_date}</td>
                                    <td style={styleTh}>{x[r].date_used!==undefined ? x[r].date_used:''}  {x[r].time_used!==undefined ? x[r].time_used :''}</td>
                                    <td style={styleTh}>{x[r].transaction_amount!==undefined ? x[r].transaction_amount :''}</td>
                                    <td style={styleTh}>{x[r].transaction_id!==undefined ? x[r].transaction_id :''}</td>
                                    <td style={styleTh}>{x[r].coupon_used!==undefined ? x[r].coupon_used :''}</td>
                                </tr>
                            ))}
                       
                        </p>
                        </details>                        
                       
                    </div>
                    </details>
           ))
          }
        </div>
    )
}


}


