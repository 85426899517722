
import * as React from "react";

const LeftInfoBoxOfCheckIn=(dat:any)=>{
const data=dat.data;
const maskNumber =dat.maskNumber;

    return (<span className="containerInfo"><div className="InfoboxLeft"> <div className="liveupdateinfo" >    
    <div>Last Check-In at {data.viewCheckIn.time}</div>
</div>

<div className="liveupdateinfo" >    
    <div style={{display:data.viewCheckIn.name!==undefined && data.viewCheckIn.name!==null ? 'block' :'none'}}>{data.viewCheckIn.name!==undefined && data.viewCheckIn.name!==null ? ' Name :' : ''} {data.viewCheckIn.name!==undefined && data.viewCheckIn.name!==null  ? data.viewCheckIn.name.substr(0, 15) : ''}</div>
    <div style={{display:data.viewCheckIn.mobile!==null && data.viewCheckIn.mobile!=='6028181729' && data.viewCheckIn.mobile!=='Opt-Out' ? 'block':'none'}}>{data.viewCheckIn.mobile!==null && data.viewCheckIn.mobile!=='6028181729' && data.viewCheckIn.mobile!=='Opt-Out' ?  'Phone Number:' : ''} {data.viewCheckIn.mobile!==undefined && data.viewCheckIn.mobile!==null && data.viewCheckIn.mobile!=='Opt-Out' ? maskNumber(data.viewCheckIn.mobile) : ''}</div>
    <div  style={{display:data.viewCheckIn.email!==null ? 'block' :'none'}}>{data.viewCheckIn.email!==null && data.viewCheckIn.email!=='' ?  'Email:' : ''} {data.viewCheckIn.email!==undefined && data.viewCheckIn.email!==null  && data.viewCheckIn.email!==''  ? data.viewCheckIn.email :''}</div>
 </div>


 <div className="liveupdateinfo overflow-scrollx" >            
    <div>{data.viewCheckIn.recheck!=='' ? 'Recheck' : 'First of the check-in of the day'}</div>
 </div>
 </div>
{data.screenSize>=750 && data.screenSize<=1180 ?
  <div style={{textAlign:'center',width:'50%',padding:'2px'}}> <label >Picture</label> 
    <div   className="imgdiv"><img className="minmaximg" src={data.viewCheckIn.picture===null || dat.data.viewCheckIn.picture==='' ? dat.data.picture : dat.data.viewCheckIn.picture }/>
    </div></div>
     : ''
     }  
  </span>
 )}

export default LeftInfoBoxOfCheckIn